import { NavLink } from 'react-router-dom';

export default function FooterColumn ({
  className = null,
  items = [],
  title = null,
  smallGap = false
}) {
  return (
    <div className={`footer-column${className ? ` ${className}` : ''}`}>
      {title ? <div className="footer-column__title">{title}</div> : null}
      {items ? (
        <ul className={`footer-column__list${smallGap ? ' footer-column__list--tight' : ''}`}>
          {items.map(item => <li key={item.key} className="footer-column__list-item">
            {item.attributes.caption && <span className="footer-column__list-item-caption">{item.attributes.caption}</span>}
            {item.attributes.url
              ? <NavLink to={item.attributes.url} className="footer-column__list-item-link">{item.attributes.text}</NavLink>
              : <span className="footer-column__list-item-title">{item.attributes.text}</span>
            }
            {item.attributes.phone
              ? (
                <a href={`tel:${item.attributes.phone}`}  className="footer-column__list-item-link">
                  {item.attributes.phone}
                </a>
              ) : null}
          </li>)}
        </ul>
      ) : null}
    </div>
  );
}
