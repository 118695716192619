import { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadInitialContent } from 'store/GlobalContentSlice';
import classNames from "classnames";

import './style/entry.scss';
import { Pages } from './pages';

import ErrorPage from "./pages/ErrorPage";
import { NotFoundPage } from "pages";
import useResetErrorOnRouteChange from 'hooks/useResetErrorOnRouteChange';

import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-T92CMVH'
}

const setConsentDefaults = () => {
  window.dataLayer = window.dataLayer || [];
  function gtag () {
    window.dataLayer.push(arguments);
  }
  gtag('consent', 'default', {
    ad_storage: 'denied',
    ad_user_data: 'denied',
    ad_personalization: 'denied',
    analytics_storage: 'denied',
    functionality_storage: 'denied',
    personalization_storage: 'denied',
    security_storage: 'granted',
    wait_for_update: 2000,
  });
  gtag('set', 'ads_data_redaction', true);
  gtag('set', 'url_passthrough', true);
};

const addEventListeners = () => {
  window.dataLayer = window.dataLayer || [];
  function gtag () {
    window.dataLayer.push(arguments);
  }

  document.addEventListener('cookieyes_banner_load', function (eventData) {
    const data = eventData.detail;
    window.cookieyes = window.cookieyes || {};
    if (data) {
      window.cookieyes.consent = {
        necessary: data?.categories?.necessary || true,
        functional: data?.categories?.functional || false,
        analytics: data?.categories?.analytics || false,
        performance: data?.categories?.performance || false,
        advertisement: data?.categories?.advertisement || false
      };
    }
  });

  document.addEventListener('cookieyes_consent_update', function (eventData) {
    const currentConsentDetails = eventData.detail;

    if (typeof currentConsentDetails === 'undefined') {
      console.warn('CookieYes: Consent update received but no details provided.');
      return;
    }

    window.cookieyes = window.cookieyes || {};
    window.cookieyes.consent = {
      functional: currentConsentDetails.accepted.includes('functional'),
      analytics: currentConsentDetails.accepted.includes('analytics'),
      performance: currentConsentDetails.accepted.includes('performance'),
      advertisement: currentConsentDetails.accepted.includes('advertisement')
    };

    const consentObject = {};
    const acceptedCookies = currentConsentDetails.accepted;
    const rejectedCookies = currentConsentDetails.rejected;

    acceptedCookies.forEach((cookieCategory) => {
      switch (cookieCategory) {
        case 'analytics':
          consentObject.analytics_storage = 'granted';
          consentObject.personalization_storage = 'granted';
          break;
        case 'advertisement':
          consentObject.ad_storage = 'granted';
          consentObject.ad_user_data = 'granted';
          consentObject.ad_personalization = 'granted';
          break;
        case 'functional':
          consentObject.functionality_storage = 'granted';
          break;
        default:
          break;
      }
    });

    rejectedCookies.forEach((cookieCategory) => {
      switch (cookieCategory) {
        case 'analytics':
          consentObject.analytics_storage = 'denied';
          consentObject.personalization_storage = 'denied';
          break;
        case 'advertisement':
          consentObject.ad_storage = 'denied';
          consentObject.ad_user_data = 'denied';
          consentObject.ad_personalization = 'denied';
          break;
        case 'functional':
          consentObject.functionality_storage = 'denied';
          break;
        default:
          break
      }
    });

    gtag('consent', 'update', consentObject);
  });
};

export default function App () {
  const dispatch = useDispatch();
  useResetErrorOnRouteChange();

  const { activePage } = useSelector(state => state.globalContent)
  let className = classNames('App', (activePage && 'App_' + activePage));

  const loading = useSelector(state => state.globalContent.loading);
  const error = useSelector(state => state.globalContent.error);

  useEffect(() => {
    setConsentDefaults();
    TagManager.initialize(tagManagerArgs)
    addEventListeners();
    dispatch(loadInitialContent());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={className}>
      <Suspense fallback={<div className="loader">Loading...</div>}>
        {error === 'Bad request' && <ErrorPage />}
        {error === 'Not found' && <NotFoundPage />}
        {!error && <Pages />}
      </Suspense>
      {loading && <div className="loader">Loading...</div>}
    </div>
  );
}
